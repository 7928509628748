
import request from '@/utils/request'

//任务列表
export function taskList(params) {
  return request({
    url: '/overage/page',
    method: 'get',
    params:params
  })
}


//任务详情
export function taskDetail(params) {
  return request({
    url: '/overage/taskDetail',
    method: 'get',
    params:params
  })
}

//任务流水
export function taskFlow(params) {
  return request({
    url: '/overage/taskLog',
    method: 'get',
    params:params
  })
}


//流水导出
export function taskExport(data) {
	return request({
		url: '/overage/exportList',
		method: 'post',
		responseType: "blob",
		params: data
	})
}



// 问题相关----
// 问题列表
export function problemPage(params) {
  return request({
    url: '/overage/problemPage',
    method: 'get',
    params:params
  })
}


//问题统计
export function problemTotal(params) {
  return request({
    url: '/overage/problem',
    method: 'get',
    params:params
  })
}

//问题处理
export function processProblem(params) {
  return request({
    url: '/overage/processProblem',
    method: 'get',
    params:params
  })
}
//批量处理
export function processProblems(params) {
  return request({
    url: '/overage/processProblems',
    method: 'get',
    params:params
  })
}

//问题详情
export function problemDetail(params) {
  return request({
    url: '/overage/problemDetail',
    method: 'get',
    params:params
  })
}




//周期修改列表----
//周期修改列表
export function changePeriodPage(params) {
  return request({
    url: '/overage/changePeriodPage',
    method: 'get',
    params:params
  })
}
//周期审核
export function changePeriodAudit(params) {
  return request({
    url: '/overage/changePeriodAudit',
    method: 'get',
    params:params
  })
}
//周期审核流水
export function auditLogPage(params) {
  return request({
    url: '/overage/auditLogPage',
    method: 'get',
    params:params
  })
}